import { useEffect, useState, useRef, Component } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { intersect,randomIntFromInterval,calculateScore,returnVisuals,number2word } from '../../../App';

export const DisasterResult = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore,
    showDisasterSelection,
    setShowDisasterSelection,
    showDisasterProfile,
    setShowDisasterProfile,
    disaster,
    disasters,
    setDisasters,
    showDisasterResult,
    setShowDisasterResult,
    showSetBackSelection,
    setShowSetBackSelection
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let threshold = {upper: 80,lower: 45};

  const retrieveScore = (player,disaster)=>{    
    let climate_adaptation_score = 0;
    let entry = '';
    if(player === 'Policy Maker')
      entry = policyMakerStrategy;
    else if(player === 'Community Leader')
      entry = communityLeaderStrategy;
    else if(player === 'Farmer')
      entry = farmerStrategy;
    Object.entries(entry).forEach((selectedCard)=>{
      if(selectedCard[0] !== "spend"){
        if(StrategyCards?.current[player][selectedCard[1]] !== undefined){
          climate_adaptation_score = climate_adaptation_score+StrategyCards?.current[player][selectedCard[1]][disaster];
        }
      }
    });
    climate_adaptation_score = Math.ceil(climate_adaptation_score/5);
    
    let coinBenefit = 50;
    let coinLoss = -20;

    let climate_adaptation_result = calculateScore(climate_adaptation_score,threshold);
    let climate_adaptation_benefit = 0;
    let climate_adaptation_loss = 0;
    if(climate_adaptation_result === "Good")
      climate_adaptation_benefit = climate_adaptation_benefit+coinBenefit;
    else if(climate_adaptation_result === "Poor")
      climate_adaptation_loss = climate_adaptation_loss+coinLoss;
    
    return({ climate_adaptation_score: climate_adaptation_score, climate_adaptation_result: climate_adaptation_result, climate_adaptation_benefit: climate_adaptation_benefit, climate_adaptation_loss: climate_adaptation_loss });
  }

  let policyMakerScores = retrieveScore('Policy Maker',disaster.current.disaster);
  let communityLeaderScores = retrieveScore('Community Leader',disaster.current.disaster);
  let farmerScores = retrieveScore('Farmer',disaster.current.disaster);

  const [triggerAdaptation,setTriggerAdaptation] = useState(true);
  useEffect(()=>{
    if(triggerAdaptation){
      let tempBenefits = {
        "Policy Maker": {cooperation: benefits['Policy Maker'].cooperation, economic: benefits['Policy Maker'].economic, environmental: benefits['Policy Maker'].environmental, social: benefits['Policy Maker'].social, climate_adaptation: policyMakerScores.climate_adaptation_benefit+policyMakerScores.climate_adaptation_loss},
        "Community Leader": {cooperation: benefits['Community Leader'].cooperation, economic: benefits['Community Leader'].economic, environmental: benefits['Community Leader'].environmental, social: benefits['Community Leader'].social, climate_adaptation: communityLeaderScores.climate_adaptation_benefit+communityLeaderScores.climate_adaptation_loss},
        "Farmer": {cooperation: benefits['Farmer'].cooperation, economic: benefits['Farmer'].economic, environmental: benefits['Farmer'].environmental, social: benefits['Farmer'].social, climate_adaptation: farmerScores.climate_adaptation_benefit+farmerScores.climate_adaptation_loss}
      }
      setBenefits({...benefits, ...tempBenefits});      
      let tempScores = {
        "Policy Maker": {economic_score: scores['Policy Maker'].economic_score, environmental_score: scores['Policy Maker'].environmental_score, social_score: scores['Policy Maker'].social_score, climate_adaptation_score: policyMakerScores.climate_adaptation_score},
        "Community Leader": {economic_score: scores['Community Leader'].economic_score, environmental_score: scores['Community Leader'].environmental_score, social_score: scores['Community Leader'].social_score, climate_adaptation_score: communityLeaderScores.climate_adaptation_score},
        "Farmer": {economic_score: scores['Farmer'].economic_score, environmental_score: scores['Farmer'].environmental_score, social_score: scores['Farmer'].social_score, climate_adaptation_score: farmerScores.climate_adaptation_score}
      }
      setScores({...scores, ...tempScores});
      setTriggerAdaptation(false);
    }
  },[policyMakerScores,communityLeaderScores,farmerScores]);

  let overall = calculateScore((policyMakerScores.climate_adaptation_score+communityLeaderScores.climate_adaptation_score+farmerScores.climate_adaptation_score)/3,threshold);
  let visuals = {"Policy Maker": returnVisuals(policyMakerScores.climate_adaptation_result),
                 "Community Leader": returnVisuals(communityLeaderScores.climate_adaptation_result),
                 "Farmer": returnVisuals(farmerScores.climate_adaptation_result),
                 "Overall": returnVisuals(overall)};

  var tempTokens = useRef(tokens);

  tempTokens.current["Policy Maker"] = {...tempTokens.current["Policy Maker"], [round]: policyMakerScores.climate_adaptation_result};
  tempTokens.current["Community Leader"] = {...tempTokens.current["Community Leader"], [round]: communityLeaderScores.climate_adaptation_result};
  tempTokens.current["Farmer"] = {...tempTokens.current["Farmer"], [round]:farmerScores.climate_adaptation_result};
  setTokens(tempTokens.current);

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{    
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div id='"+rounds[0]+"PM' class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";      
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div id='"+rounds[0]+"CL' class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";      
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div id='"+rounds[0]+"F' class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";      
    }
  });

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation+benefits['Policy Maker'].economic+benefits['Policy Maker'].environmental+benefits['Policy Maker'].social+benefits['Policy Maker'].climate_adaptation;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation+benefits['Community Leader'].economic+benefits['Community Leader'].environmental+benefits['Community Leader'].social+benefits['Community Leader'].climate_adaptation;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation+benefits['Farmer'].economic+benefits['Farmer'].environmental+benefits['Farmer'].social+benefits['Farmer'].climate_adaptation;
  
  return (
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '2.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Your choices against {disaster.current.disaster.charAt(0).toUpperCase()+disaster.current.disaster.slice(1)}</span>
          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: '400', textAlign: 'center' }}>From Adaptation to Transformation!</p>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+'+'+benefits['Policy Maker'].economic+'+'+benefits['Policy Maker'].environmental+'+'+benefits['Policy Maker'].social+'+'+benefits['Policy Maker'].climate_adaptation} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+'+'+benefits['Community Leader'].economic+'+'+benefits['Community Leader'].environmental+'+'+benefits['Community Leader'].social+'+'+benefits['Community Leader'].climate_adaptation} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+'+'+benefits['Farmer'].economic+'+'+benefits['Farmer'].environmental+'+'+benefits['Farmer'].social+'+'+benefits['Farmer'].climate_adaptation} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">

        <div className="cell cell3vmerge">
          <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#ddd", color: "#333"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('DisasterResult','DisasterProfile'))} />
        </div>

        <div className="cell cell3vmerge flex-column">
          
        <div className="flex flex-row align-items-start justify-content-center p-0 m-0 gap-2" style={{ height: 'fit-content', width: 'fit-content' }}>
            <div className="flex flex-column p-2" style={{width: '20vw', height: '100%', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px' }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/policy_maker.png' alt='Policy Maker' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-2" style={{ fontSize: '1.5vw', textAlign: 'center', fontWeight: '900' }}>Policy Maker</p>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Policy Maker'][policyMakerStrategy[1]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Policy Maker'][policyMakerStrategy[1]].strategy} ({policyMakerStrategy[1]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Policy Maker'][policyMakerStrategy[2]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Policy Maker'][policyMakerStrategy[2]].strategy} ({policyMakerStrategy[2]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Policy Maker'][policyMakerStrategy[3]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Policy Maker'][policyMakerStrategy[3]].strategy} ({policyMakerStrategy[3]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Policy Maker'][policyMakerStrategy[4]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Policy Maker'][policyMakerStrategy[4]].strategy} ({policyMakerStrategy[4]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Policy Maker'][policyMakerStrategy[5]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Policy Maker'][policyMakerStrategy[5]].strategy} ({policyMakerStrategy[5]})</p></div>
              <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 mt-2" style={{ height: '40px', width: '40px', background: visuals['Policy Maker'].color, color: '#fff', borderRadius: '50%' }}><i className={visuals['Policy Maker'].icon+' m-1 p-0'} style={{ fontSize: '1.5rem' }} /></div>
            </div>
           <div className="flex flex-column p-2" style={{width: '20vw', height: '100%', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px' }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/community_leader.png' alt='Community Leader' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-2" style={{ fontSize: '1.5vw', textAlign: 'center', fontWeight: '900' }}>Community Leader</p>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Community Leader'][communityLeaderStrategy[1]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Community Leader'][communityLeaderStrategy[1]].strategy} ({communityLeaderStrategy[1]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Community Leader'][communityLeaderStrategy[2]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Community Leader'][communityLeaderStrategy[2]].strategy} ({communityLeaderStrategy[2]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Community Leader'][communityLeaderStrategy[3]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Community Leader'][communityLeaderStrategy[3]].strategy} ({communityLeaderStrategy[3]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Community Leader'][communityLeaderStrategy[4]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Community Leader'][communityLeaderStrategy[4]].strategy} ({communityLeaderStrategy[4]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Community Leader'][communityLeaderStrategy[5]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Community Leader'][communityLeaderStrategy[5]].strategy} ({communityLeaderStrategy[5]})</p></div>
              <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 mt-2" style={{ height: '40px', width: '40px', background: visuals['Community Leader'].color, color: '#fff', borderRadius: '50%' }}><i className={visuals['Community Leader'].icon+' m-1 p-0'} style={{ fontSize: '1.5rem' }} /></div>
            </div>
            <div className="flex flex-column p-2" style={{width: '20vw', height: '100%', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px' }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/farmer.png' alt='Farmer' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-2" style={{ fontSize: '1.5vw', textAlign: 'center', fontWeight: '900' }}>Farmer</p>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Farmer'][farmerStrategy[1]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Farmer'][farmerStrategy[1]].strategy} ({farmerStrategy[1]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Farmer'][farmerStrategy[2]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Farmer'][farmerStrategy[2]].strategy} ({farmerStrategy[2]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Farmer'][farmerStrategy[3]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Farmer'][farmerStrategy[3]].strategy} ({farmerStrategy[3]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Farmer'][farmerStrategy[4]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Farmer'][farmerStrategy[4]].strategy} ({farmerStrategy[4]})</p></div>
              <div className="flex flex-row p-0 m-0 mb-1"><div className="p-0 m-0 mr-1" style={{ width: '20px', minWidth: '20px', height: '20px', borderRadius: '50%', background: returnVisuals(calculateScore(StrategyCards.current['Farmer'][farmerStrategy[5]][disaster.current.disaster],threshold)).color }} >&nbsp;</div><p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>{StrategyCards.current['Farmer'][farmerStrategy[5]].strategy} ({farmerStrategy[5]})</p></div>
              <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 mt-2" style={{ height: '40px', width: '40px', background: visuals['Farmer'].color, color: '#fff', borderRadius: '50%' }}><i className={visuals['Farmer'].icon+' m-1 p-0'} style={{ fontSize: '1.5rem' }} /></div>
            </div>
          </div>

          <div className="flex flex-column align-self-center align-items-center justify-content-center p-0 m-0" style={{ maxHeight: '25%', width: '70%', borderRadius: '20px' }}>
            <p className="p-0 m-0" style={{ fontSize: '1.25vw', fontWeight: 'bolder', color: 'white', textShadow: '2px 2px 4px #000' }}>Congratulations, the collective efforts of all players have led to a</p>
            <div className="flex flex-row align-items-center">
              <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '40px', height: '40px', borderRadius: '50%', background: visuals['Overall'].color, color: 'white', textAlign: 'center' }} >
                <i className={visuals['Overall'].icon} style={{ fontSize: '1.5rem' }} />
              </div>
              <span style={{ fontSize: '1.25vw', fontWeight: 'bolder', color: 'white', textShadow: '2px 2px 4px #000' }}> {overall} Score</span>
            </div>
            {(policyMakerScores.climate_adaptation_benefit > 0 || communityLeaderScores.climate_adaptation_benefit > 0 || farmerScores.climate_adaptation_benefit > 0) && 
              <>
                <div className="z-1 absolute flex flex-column align-items-center justify-content-center gap-2 p-4 mb-2" style={{ left: '10px', top: '200px', transform: "rotate(-5deg)", backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px', width: '270px' }}>                  
                  <p className="p-0 m-0" style={{ color: '#235F46', fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>The {policyMakerScores.climate_adaptation_benefit > 0? 'Policy Maker ': ''}{communityLeaderScores.climate_adaptation_benefit > 0? 'Community Leader ': ''}{farmerScores.climate_adaptation_benefit > 0? 'Farmer ': ''} picked good strategies and are rewarded for their excellence!</p>                  
                </div>
                <div className="z-2 absolute flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ left: '270px', top: '200px', transform: "rotate(15deg)", backgroundColor: '#235F46', borderRadius: '50%', width: '6.5vw', height: '6.5vw' }}>
                  <p className="p-0 m-0" style={{ color: '#fff', fontSize: '0.75vw', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-regular fa-face-smile-beam" style={{ fontSize: '1.5rem' }}></i><br/>The player gets an additional 50 coins</p>
                </div>
              </>
            }
            {(policyMakerScores.climate_adaptation_loss < 0 || communityLeaderScores.climate_adaptation_loss < 0 || farmerScores.climate_adaptation_loss < 0) && 
              <>
                <div className="z-1 absolute flex flex-column align-items-center justify-content-center gap-2 p-4 mb-2" style={{ right: '10px', bottom: '30px', transform: "rotate(-5deg)", backgroundImage: 'linear-gradient(rgba(255,25,25,1), rgba(255,25,25,0.5))', borderRadius: '25px', width: '270px' }}>                                
                   <p className="p-0 m-0" style={{ color: '#FFFCF3', fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>The {policyMakerScores.climate_adaptation_loss < 0? 'Policy Maker ': ''}{communityLeaderScores.climate_adaptation_loss < 0? 'Community Leader ': ''}{farmerScores.climate_adaptation_loss < 0? 'Farmer ': ''} picked poor strategies for this climate disaster, coins will be deducted!!</p>
                </div>
                <div className="z-2 absolute flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ right: '270px', bottom: '30px', transform: "rotate(-15deg)", backgroundColor: 'red', borderRadius: '50%', width: '6vw', height: '6vw' }}>
                  <p className="p-0 m-0" style={{ color: '#fff', fontSize: '0.75vw', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-regular fa-face-sad-tear" style={{ fontSize: '1.5rem' }}></i><br/>The player loses 20 coins</p>
                </div>
              </>
            }
          </div>

        </div>
        <div className="cell cell3vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('DisasterResult','SetBackSelection'))} />
        </div>
      
      </div>
    </div>
  );
}
