import { Button } from 'primereact/button';

export const Landing = (props) => {
  const {showComponent,hideComponent,showLanding,setShowLanding,showInstruction,setShowInstruction,setShowConfiguration,setShowPreGame,setShowStrategy} = props;

  //setShowLanding(false);
  setShowInstruction(false);
  setShowConfiguration(false); 
  setShowPreGame(false);
  setShowStrategy(false);
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }

  return (    
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell cell2hmerge">
          &nbsp;
        </div>
      </div>

      <div className="content">          
          <div className="cell cell3hmerge cell2vmerge flex-column">
            <p style={{ color: '#38BA8B', fontSize: '3vw', textAlign: 'center', fontWeight: '400', padding: '0px', margin: '0px', paddingTop: '10px' }}>Welcome to</p>
            <p style={{ fontSize: '6vw', textAlign: 'center', fontWeight: '900', padding: '0px', margin: '0px', textShadow: '0px 1px, 1px 0px, 1px 1px' }}>AgroAdapt</p>
            <p style={{ color: '#38BA8B', fontSize: '3vw', textAlign: 'center', fontWeight: '900', padding: '0px', margin: '0px' }}>Cultivate the Future</p>
            <p style={{ fontSize: '1.5vw', textAlign: 'center', fontWeight: '400', padding: '0px', margin: '0px', paddingTop: '10px' }}>AgroAdapt is a hybrid simulation<br />board game to tackle climate change<br/>issues on agrifood systems</p>
          </div>
          <div className="cell cell3hmerge align-contents-center justify-contents-center">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1 " style={{ fontFamily: 'AgroAdapt', backgroundColor: "#FFFCF3", color: "#38BA8B", fontSize: "20px", border: "1px solid #38BA8B" }} label="What does it consist of?" onClick={()=>processClick('Landing','Instruction')} rounded raised />
          </div>
      </div>
    </div>    
  )
}
